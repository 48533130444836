import * as React from 'react'
import { Icon } from '../icon/icon'
import { Button, type ButtonProps } from './button'
import { cn } from '~/utils/misc'

export const StatusButton = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & { status: 'pending' | 'success' | 'error' | 'idle' }
>(({ status = 'idle', className, children, ...props }, ref) => {
	const companion = {
		pending: <Icon name="spinner" className="animate-spin text-secondary" />,
		success: <Icon name="check-circle" className="text-success" size="md" />,
		error: (
			<Icon name="exclamation-circle" className="text-destructive" size="md" />
		),
		idle: null,
	}[status]
	return (
		<Button
			ref={ref}
			className={cn('flex justify-center gap-4', className)}
			{...props}
		>
			{children}
			{companion}
		</Button>
	)
})
StatusButton.displayName = 'Button'
